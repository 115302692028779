import { template as template_9c6edc309377491ab3ca1c96b86ff34a } from "@ember/template-compiler";
const FKText = template_9c6edc309377491ab3ca1c96b86ff34a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
