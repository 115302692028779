import { template as template_71b7137092d34765bad892fa705ee740 } from "@ember/template-compiler";
const SidebarSectionMessage = template_71b7137092d34765bad892fa705ee740(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
