import { template as template_f4dbfa2b82524358aceaedad4f31a73e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_f4dbfa2b82524358aceaedad4f31a73e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
