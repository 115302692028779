import { template as template_b1d0a79e8e5a4430bea246c871cb34e7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b1d0a79e8e5a4430bea246c871cb34e7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
