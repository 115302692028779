import { template as template_b0f588a8769d45acb9ee6d1f36c38e57 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_b0f588a8769d45acb9ee6d1f36c38e57(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_b0f588a8769d45acb9ee6d1f36c38e57(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_b0f588a8769d45acb9ee6d1f36c38e57(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
